import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CommonFields } from './commonFields';

import { isIntNumberOrDash } from '../../../../bi/utils/trip';
import { dateAfterOtherDate } from '../../../../bi/utils/hotels';
import { diffDays, isMoment, isValidMomentObject, momentObject } from '../../../../bi/utils/formatDate';

import { FIELDS, INN_LENGHTS } from '../../../../bi/constants/trips';

import styles from '../../styles/form.module.scss';

const NewGroupHotelForm = ({
  hotels,
  tripService,
  isItemCart,
}) => {
  const {
    groupHotels: {
      ProviderInn,
      isNewTrip,
    },
    groupHotels,
  } = tripService.get();

  useEffect(() => {
    tripService.changeJsonGroupBooking(hotels.JsonData);
  }, []);

  const shouldCheckOutDateFirst = (field, JsonData) => {
    const { CheckoutDate } = JsonData;

    return !dateAfterOtherDate(JsonData) && field === FIELDS.CHECKOUT_DATE && isMoment(CheckoutDate) && CheckoutDate.isValid();
  };

  const shouldPerformStandardValidation = (field, CheckinDate, CheckoutDate) => (
    (field === FIELDS.CHECKIN_DATE || field === FIELDS.CHECKOUT_DATE) &&
     (CheckinDate || CheckoutDate) &&
     (isValidMomentObject(CheckinDate) || isValidMomentObject(CheckoutDate))
 );

  const handleCheckoutValidation = (JsonData, isGetResultCheckout = false) => {
    const resultCheckin = tripService.hotelTripFieldValidation(FIELDS.CHECKIN_DATE, JsonData);
    const resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    const resultCheckout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUT_DATE, JsonData);

    if (isGetResultCheckout) return { resultCheckin, resultCheckoutLess, resultCheckout };

    return { resultCheckin, resultCheckoutLess };
  };

  const getDataValidationObj = (field, JsonData) => {
    const { CheckinDate, CheckoutDate } = JsonData;

    if (shouldCheckOutDateFirst(field, JsonData)) {
      return handleCheckoutValidation(JsonData);
    }

    if (shouldPerformStandardValidation(field, CheckinDate, CheckoutDate)) {
      return handleCheckoutValidation(JsonData, true);
    }

    return { resultCheckin: '', resultCheckoutLess: '', resultCheckout: '' };
  };

  const getFieldAndValueValid = (field, JsonData, defaultResult) => {
    const { resultCheckin, resultCheckoutLess, resultCheckout } = getDataValidationObj(field, JsonData);

    if (resultCheckin) return { fieldObj: FIELDS.CHECKIN_DATE, value: resultCheckin };

    if (resultCheckout || resultCheckoutLess) {
      const value = resultCheckout || resultCheckoutLess;

      return { fieldObj: FIELDS.CHECKOUT_DATE, value };
    }

    return { fieldObj: field, value: defaultResult };
  };

  const validateDate = (field) => {
    const { JsonData } = groupHotels;
    const defaultResult = tripService.hotelTripFieldValidation(field, JsonData[field]);

    const { fieldObj, value } = getFieldAndValueValid(field, JsonData, defaultResult);

    tripService.setValidationGroupHotel(fieldObj, value);
  };

  const validateInput = (field) => {
    const { JsonData } = groupHotels;
    const part = field.split('.');
    const value = part.length === 1 ? JsonData[field] : JsonData[part[0]][part[1]];
    const result = tripService.hotelTripFieldValidation(field, value);

    tripService.setValidationGroupHotel(field, result);
  };

  const handleChangeJsonInput = (field, value) => {
    const { JsonData } = groupHotels;

    tripService.changeJsonInputGroupHotel(field, value, JsonData);
  };

  const handleSetNightsCount = () => {
    const { CheckinDate, CheckoutDate } = hotels.JsonData;

    if (isValidMomentObject(CheckinDate) && isValidMomentObject(CheckoutDate)) {
      const diff = diffDays(momentObject(CheckinDate), momentObject(CheckoutDate));
      handleChangeJsonInput(FIELDS.NIGHTS_COUNT, diff);
    }
  };

  const handleChangeNumberInput = (field, value) => {
    if ((value === '' || isIntNumberOrDash(value)) && value.length <= INN_LENGHTS.MAX) {
      tripService.changeFieldGroupBooking(field, value);
    }
  };

  const handleChangeDate = (field, value) => {
    tripService.changeDateGroupHotel(field, value, groupHotels.JsonData);

    handleSetNightsCount();
  };

  const validateProviderInn = (field) => {
    const result = tripService.hotelTripFieldValidation(field, ProviderInn);
    tripService.setValidationGroupHotel(field, result);
  };

  const getFuncProps = () => ({
    onChangeNumberInput: handleChangeNumberInput,
    onValidateInn: validateProviderInn,
    onValidateDate: validateDate,
    onValidateInput: validateInput,
    onChangeDate: handleChangeDate,
    onChangeJsonInput: handleChangeJsonInput,
  });

  return (
    <div className={ styles.wrap }>
      <CommonFields
        isCreateOrder
        isItemCart={ isItemCart }
        funcProps={ getFuncProps() }
        isNewTrip={ isNewTrip }
        groupHotels={ groupHotels }
        tripService={ tripService }
      />
    </div>
  );
};

NewGroupHotelForm.propTypes = {
  hotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  isItemCart: PropTypes.bool.isRequired,
};

NewGroupHotelForm.defaultProps = {
  isItemCart: false,
};

export { NewGroupHotelForm };
