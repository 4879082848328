import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog } from 'sw-ui';

import { Providers } from './components/Providers';
import { StarsHotel } from './components/AutocomleteHotel/components/StarsHotel';
import { HotelName } from './components/AutocomleteHotel/components/HotelName';
import { HotelAddress } from './components/AutocomleteHotel/components/HotelAddress';
import { HotelCity } from './components/AutocomleteHotel/components/HotelCity';
import { HotelPhone } from './components/AutocomleteHotel/components/HotelPhone';
import { HotelCountry } from './components/AutocomleteHotel/components/HotelCountry';
import { HotelCheckinCheckout } from './components/AutocomleteHotel/components/HotelCheckinCheckout';
import { HotelEmail } from './components/AutocomleteHotel/components/HotelEmail';

import COMPONENTS from '../../../../bi/constants/components';
import { FIELDS } from '../../../../bi/constants/trips';
import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';

import styles from '../../styles/form.module.scss';

const EditMainGroupBooking = ({
  groupHotels,
  tripService,
  tripService: {
    changeFieldGroupBooking,
    resetMainHotelFields,
    hotelTripFieldValidation,
    setValidationGroupHotel,
  },
  funcProps: {
    onChangeProviderName,
    onChangeJsonInput,
    onValidateInput,
  },
}) => {
  const validationConfirm = () => {
    const { JsonData } = groupHotels;
    const {
      Hotel: {
        Name,
        Address,
        CheckinTime,
        CheckoutTime,
        City,
        Location,
      },
    } = JsonData;

    const name = hotelTripFieldValidation(FIELDS.HOTELNAMEUPPER, Name);
    const address = hotelTripFieldValidation(FIELDS.HOTELADDRESS, Address);
    const city = hotelTripFieldValidation(FIELDS.HOTELCITY, City);
    const location = hotelTripFieldValidation(FIELDS.HOTEL_LOCATION, Location);
    const checkinTime = hotelTripFieldValidation(FIELDS.HOTELCHECKINTIME, CheckinTime);
    const checkoutTime = hotelTripFieldValidation(FIELDS.HOTELCHECKOUTTIME, CheckoutTime);

    const validateObj = {
      Name: name,
      Address: address,
      City: city,
      CheckinTime: checkinTime,
      CheckoutTime: checkoutTime,
      Location: location,
    };

    setValidationGroupHotel(FIELDS.HOTEL, validateObj);

    const isNotValid = !!name
      || !!address
      || !!city
      || !!checkinTime
      || !!checkoutTime
      || !!location;

    return isNotValid;
  };

  const handleConfirm = () => {
    const isNotValid = validationConfirm();

    if (!isNotValid) {
      changeFieldGroupBooking(FIELDS.SHOW_MODAL_EDIT_MAIN_HOTEL_FIELDS, false);
      changeFieldGroupBooking(FIELDS.DISABLE_MAIN_HOTEL_FIELDS, true);
    }
  };

  const handleCancel = () => {
    resetMainHotelFields();
    changeFieldGroupBooking(FIELDS.SHOW_MODAL_EDIT_MAIN_HOTEL_FIELDS, false);
    changeFieldGroupBooking(FIELDS.DISABLE_MAIN_HOTEL_FIELDS, true);
  };

  const renderContent = () => {
    const { JsonData: data } = groupHotels;

    return (
      <div className={ styles.dialog }>
        <div>
          <div className={ styles.row }>
            <HotelName
              groupHotels={ groupHotels }
              onValidateInput={ onValidateInput }
              onChangeJsonInput={ onChangeJsonInput }
              tripService={ tripService }
            />
            <div className={ styles['col-1-4'] }>
              <div className={ styles.select }>
                <StarsHotel
                  onChangeJsonInput={ onChangeJsonInput }
                  groupHotels={ groupHotels }
                />
              </div>
            </div>
            <Providers
              onChangeProviderName={ onChangeProviderName }
              providerName={ data.ProviderName }
              tripService={ tripService }
            />
          </div>
          <HotelAddress
            onValidateInput={ onValidateInput }
            onChangeJsonInput={ onChangeJsonInput }
            groupHotels={ groupHotels }
          />
          <div className={ styles.row }>
            <HotelCity
              onValidateInput={ onValidateInput }
              onChangeJsonInput={ onChangeJsonInput }
              groupHotels={ groupHotels }
              tripService={ tripService }
            />
            <HotelCountry
              onValidateInput={ onValidateInput }
              onChangeJsonInput={ onChangeJsonInput }
              groupHotels={ groupHotels }
              tripService={ tripService }
            />
            <div className={ styles['col-1-3'] }>
              <HotelPhone
                onChangeJsonInput={ onChangeJsonInput }
                groupHotels={ groupHotels }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-2'] }>
              <HotelEmail
                groupHotels={ groupHotels }
                onChangeJsonInput={ onChangeJsonInput }
                tripService={ tripService }
              />
            </div>
          </div>
          <HotelCheckinCheckout
            groupHotels={ groupHotels }
            onChangeJsonInput={ onChangeJsonInput }
            tripService={ tripService }
          />
        </div>
        <div className={ styles.dialog_buttons }>
          <Button
            label={ DECISIONBUTTONS.LABELS.SAVE }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            className={ styles.save_button }
            onClick={ handleConfirm }
          />
          <Button
            label={ DECISIONBUTTONS.LABELS.CANCEL }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            onClick={ handleCancel }
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      onClick={ handleCancel }
      width='100%'
    >
      { renderContent() }
    </Dialog>
  );
};

EditMainGroupBooking.propTypes = {
  groupHotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  funcProps: PropTypes.object.isRequired,
};

export { EditMainGroupBooking };
