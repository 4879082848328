import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import DatePicker from 'react-datetime';
import { Checkbox as CheckboxUI, Button, Dialog } from 'sw-ui';

import { FlatButton } from '../../../../components/button';
import EmployeeSuggest from '../../../../components/employee';
import TPDetails from '../../../../components/tpDetails';
import Input from '../../../../components/input';
import Checkbox from '../../../../components/checkbox';
import ContractHotel from '../../../../components/ContractHotel';

import {
  dateParseZone,
  getMoment,
  getDate,
  isBeforeDateParameter,
} from '../../../../bi/utils/formatDate';
import { generateEmployeePlaceholder, getActualEmployees, preventKeyNotNumber } from '../../../../bi/utils/trip';

import { TRIPSLABELS, FIELDS, PRICELABELS } from '../../../../bi/constants/trips';
import COMPONENTS from '../../../../bi/constants/components';
import { HOTEL_PROVIDER_VALUE, PROVIDERS_AVAILABLE_INN } from '../../../../bi/constants/hotel';
import { LOCALE } from '../../../../bi/constants/hotels';
import {
  DEFAULTDATE,
  DEFAULTTIME,
  LOCALERU,
} from '../../../../constants/time';

import SelectDepartment from '../selectDepartment';
import SelectProject from '../selectProject';
import SelectCompany from '../selectCompany';
import AnalyticsRows from '../analyticsRows';

import { EditMainHotelFields } from './editMainHotelFields';
import { MealFields } from './components/MealFields';

import styles from '../../styles/form.module.scss';

const BUTTONS = {
  ADD: '+ добавить условие по штрафам',
  REMOVE: '- удалить условие',
};

const LABELS = {
  RATE_SW: 'Собственный тариф смартвэй',
  PERIOD: 'Период',
  ADD_HOTEL: 'Добавить отель',
  TEXT_ADD_HOTEL: 'Вы уверены, что отеля нет в выпадающем списке?',
  YES: 'Да',
  NO: 'Нет',
  CANCELLATION_CONDITION: 'Условия отмены',
  UPDATE_CANCELLATION_CONDITION: 'Обновите условия штрафов',
  SELECT_MEAL: 'Выберите питание',
};

const CANCELLATION_PENALTIES = {
  FROM: 'From',
};

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 40,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, .15)',
};

class CommonFields extends Component {
  static propTypes = {
    isCreateOrder: PropTypes.bool,
    mandatoryProject: PropTypes.array,
    rates: PropTypes.array,
    ratePlaneId: PropTypes.number,
    contract: PropTypes.object,
    connected: PropTypes.object,
    compared: PropTypes.object,
    hotelProps: PropTypes.object.isRequired,
    funcProps: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    validation: PropTypes.object.isRequired,
    priceDetails: PropTypes.object.isRequired,
    meal: PropTypes.array.isRequired,
    cancellationPenalties: PropTypes.array,
    errors: PropTypes.object.isRequired,
    isNewTrip: PropTypes.bool,
    analytics: PropTypes.array,
    handleSelectAnalytics: PropTypes.func,
    tripAnalyticsValues: PropTypes.arrayOf(PropTypes.number),
    serviceAnalyticsValues: PropTypes.arrayOf(PropTypes.number),
    country: PropTypes.string,
    tripService: PropTypes.object.isRequired,
    updateMainHotelFields: PropTypes.func.isRequired,
    isItemCart: PropTypes.bool.isRequired,
    isFromOrderByNumber: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isCreateOrder: false,
    isNewTrip: false,
    analytics: [],
    tripAnalyticsValueIds: [],
    rates: [],
    ratePlaneId: 0,
    connected: null,
    compared: null,
    contract: null,
    country: '',
    mandatoryProject: [],
    handleSelectAnalytics: () => {},
    tripAnalyticsValues: [],
    serviceAnalyticsValues: [],
    cancellationPenalties: null,
  };

  constructor(props) {
    super(props);
    const {
      isNewTrip,
      data: {
        ProviderName,
      },
    } = this.props;

    const disableFieldForEdit =
      !isNewTrip &&
      (ProviderName === HOTEL_PROVIDER_VALUE.tripcom ||
      ProviderName === HOTEL_PROVIDER_VALUE.expedia);
    const disabledFieldStyles = disableFieldForEdit ? styles['disabled-field'] : '';

    this.state = {
      showModal: false,
      showModalEditMainHotelFields: false,
      disableHotelAutocomplete: false,
      disableMainHotelFields: true,
      disableFieldForEdit,
      disabledFieldStyles,
    };
  }

  getActualEmployees = (list, exclude) => list && list.filter(({ Id }) => exclude.every(({ Id: excludeId }) => !excludeId || excludeId !== Id));

  renderEmployeeSuggest = ({ tpDetails }, index) => {
    const {
      hotelProps: {
        employeesList,
        employees,
      },
      validation: {
        EmployeeFirst,
      },
      funcProps: {
        onRemoveEmployeeSuggest,
        onSelectEmployeeSuggest,
        onEmployeeFocusSuggest,
        onChangeEmployeeSuggest,
      },
      isItemCart,
    } = this.props;

    const col = employeesList.length > 3 ? employeesList.length : 3;

    return (
      <div
        className={ `${styles[`col-1-${col}`]} ${styles['employee-item-suggest']}` }
        key={ `employee_suggest_${index}` }
      >
        <EmployeeSuggest
          items={ this.getActualEmployees(employees, employeesList) }
          selected={ employeesList[index] }
          placeholder={ generateEmployeePlaceholder(index) }
          onRemove={ () => onRemoveEmployeeSuggest(index) }
          onSelect={ item => onSelectEmployeeSuggest(item, index) }
          valid={ index === 0 ? EmployeeFirst : null }
          onFocus={ onEmployeeFocusSuggest }
          onChange={ onChangeEmployeeSuggest }
        />
        <TPDetails
          details={ tpDetails }
          isItemCart={ isItemCart }
        />
      </div>
    );
  };

  renderCompany = () => {
    const {
      hotelProps: {
        companies,
        companyId,
      },
      validation: {
        CompanyId,
      },
      funcProps: {
        onSelectCompany,
      },
    } = this.props;

    return (
      <div className={ styles.row }>
        <SelectCompany
          currentCompanyId={ companyId }
          companies={ companies }
          onSelect={ onSelectCompany }
          validationCompany={ CompanyId }
        />
      </div>
    );
  };

  renderAnalytics = () => {
    const { analytics, tripAnalyticsValues, handleSelectAnalytics, serviceAnalyticsValues, validation } = this.props;

    return (
      <AnalyticsRows
        analytics={ analytics }
        serviceAnalyticsValueIds={ serviceAnalyticsValues }
        tripAnalyticsValueIds={ tripAnalyticsValues }
        onSelect={ handleSelectAnalytics }
        validation={ validation.analytics }
      />
    );
  };

  renderDay = (props, currentDate) => {
    const todayDate = getMoment();
    const currentDateFormat = getDate(currentDate);

    if (isBeforeDateParameter(currentDate, todayDate)) {
      return <td { ...props } className={ `${props.className} ${styles['date-before']}` }>{ currentDateFormat }</td>;
    }

    return <td { ...props }>{ currentDateFormat }</td>;
  }

  renderReservationDate = () => {
    const {
      isCreateOrder,
      data: {
        ReservationDate,
      },
      validation: {
        ReservationDate: validationReservationDate,
      },
      funcProps: {
        onChangeDate,
        onValidateDate,
      },
      isItemCart,
    } = this.props;
    const { disabledFieldStyles } = this.state;

    const isValidate = !isItemCart && validationReservationDate;
    const validationHtml = isValidate && <span className='error-msg'>{ validationReservationDate }</span>;

    const datePickerHtml = () => (
      <div className={ styles['date-col'] }>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat={ DEFAULTTIME }
          locale={ LOCALERU }
          value={ ReservationDate }
          onChange={ value => onChangeDate(FIELDS.RESERVATIONDATE, value) }
          onBlur={ () => !isItemCart ? onValidateDate(FIELDS.RESERVATIONDATE) : () => {} }
          className={ `componentHook ${isValidate ? 'no-valid' : ''} ${disabledFieldStyles}` }
        />
        <label>Дата бронирования</label>
        { validationHtml }
      </div>
    );

    if (isCreateOrder) {
      return (
        <div className={ `${styles.row} ${styles['date-wrap']}` }>
          { datePickerHtml() }
          <div className={ `${styles.row} ${styles['date-link']}` }>
            <div className={ styles.link } onClick={ () => onChangeDate(FIELDS.RESERVATIONTODAY) }>Сегодня</div>
            <div className={ styles.link } onClick={ () => onChangeDate(FIELDS.RESERVATIONYESTERDAY) }>Вчера</div>
          </div>
        </div>
      );
    }

    return datePickerHtml();
  };

  handleAddHotel = () => this.setState({ showModal: true });

  renderHotelNameAutocomplete = () => {
    const {
      validation: {
        Hotel: {
          Name: validationName,
        },
      },
      hotelProps: {
        hotelName: {
          label,
          suggests,
        },
      },
      funcProps: {
        onSelectSuggest,
        onChangeSuggest,
      },
      contract,
      connected,
      compared,
    } = this.props;
    const { disabledFieldStyles, disableFieldForEdit } = this.state;

    const itemHtml = ({ Id, Name, FullName }) => (
      <div key={ Id } className={ styles.autocomplete_item }>
        <div className={ styles.autocomplete_item_text }>{ Name }</div>
        <div className={ styles.autocomplete_item_label }>{ FullName }</div>
      </div>
    );

    const validHotelNameHtml = validationName && <span className={ `error-msg ${styles['error-msg-company']}` }>
      { validationName }
    </span>;

    const styleValidation = validationName ? styles['no-valid-hotel-name'] : '';

    return (
      <div className={ styles.row }>
        <div className={ styles['col-7-8'] }>
          <label>{ TRIPSLABELS.SELECT_HOTEL }</label>
          <div className={ `${styles['auto-input']} ${disabledFieldStyles} ${styles.wrap} ${styleValidation}` }>
            <Autocomplete
              menuStyle={ MENUSTYLE }
              value={ label }
              items={ suggests }
              getItemValue={ item => item.Name }
              onSelect={ (value, hotel) => onSelectSuggest(FIELDS.HOTELNAME, value, hotel) }
              onChange={ (e, value) => onChangeSuggest(e, FIELDS.HOTELNAME, value) }
              renderItem={ item => itemHtml(item) }
            />
            <ContractHotel
              contract={ contract }
              connected={ connected }
              compared={ compared }
            />
            { validHotelNameHtml }
          </div>
        </div>
        <div className={ styles['col-1-8'] }>
          <Button
            label={ LABELS.ADD_HOTEL }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            className={ styles.save_button }
            onClick={ this.handleAddHotel }
            disabled={ disableFieldForEdit }
          />
        </div>
      </div>
    );
  }

  renderHotelName = () => {
    const {
      validation: {
        Hotel: {
          CustomHotelName: validationName,
        },
      },
      hotelProps: {
        CustomHotelName,
      },
      funcProps: {
        onChangeStateInput,
        onValidateInput,
      },
    } = this.props;

    return (
      <div className={ styles['col-3-6'] }>
        <div className={ `${styles['auto-input']} ${styles.wrap} ${validationName ? styles['no-valid-hotel-name'] : ''}` }>
          <Input
            field={ FIELDS.CUSTOM_HOTEL_NAME }
            value={ CustomHotelName }
            label={ TRIPSLABELS.HOTELNAME }
            onChange={ onChangeStateInput }
            onBlur={ onValidateInput }
            disabled={ this.state.disableMainHotelFields }
          />
        </div>
      </div>
    );
  };

  renderCustomRate = () => {
    const {
      isCreateOrder,
      data: {
        IsCustom,
      },
    } = this.props;

    return IsCustom && !isCreateOrder && (
      <div className={ styles['label-custom'] }>
        <h4>{ LABELS.RATE_SW }</h4>
      </div>
    );
  };

  renderCurrencyPrice = (penaltyBaseCurrency, penaltyMFCurrency, penaltyMFRub, penaltyInclusiveCurrency, idx) => {
    const {
      funcProps: { onChangePenaltyInput },
      data: { ProviderName },
      isNewTrip,
    } = this.props;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.expedia || !isNewTrip) return null;

    return (
      <div>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.MF_CURRENCY_PENALTY }
              value={ penaltyMFCurrency }
              label={ TRIPSLABELS.MF_CURRENCY_PENALTIES }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, idx) }
              disabled
            />
          </div>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.BASE_CURRENCY_PENALTY }
              value={ penaltyBaseCurrency }
              label={ TRIPSLABELS.SUM_BASE_CURRENCY }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, idx) }
              disabled
            />
          </div>
        </div>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.MF_RUB_PENALTY }
              value={ penaltyMFRub }
              label={ TRIPSLABELS.MF_RUB_PENALTIES }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, idx) }
              disabled
            />
          </div>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.INCLUSIVE_CURRENCY_PENALTY }
              value={ penaltyInclusiveCurrency }
              label={ PRICELABELS.INCLUSIVE }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, idx) }
            />
          </div>
        </div>
      </div>
    );
  };

  renderCancellationPenalties = (
    penaltyValue,
    penaltyTotal,
    penaltyBase,
    penaltyBaseCurrency,
    penaltyMFCurrency,
    penaltyMFRub,
    penaltyInclusiveCurrency,
    penaltyAdditional,
    penaltyCustom,
    i,
  ) => {
    const {
      funcProps: {
        onRemoveCancellation,
        onChangePenaltyInput,
      },
      data: {
        ProviderName,
        Room: {
          FreeCancellation,
        },
      },
      validation: {
        Total: total,
        From: from,
        Penalty: {
          Base: base,
        },
      },
      isNewTrip,
      isItemCart,
    } = this.props;
    const { disableFieldForEdit, disabledFieldStyles } = this.state;

    const isValidValue = isNewTrip && FreeCancellation;
    const validFromHtml = from && <span className='error-msg'>{ from[i] }</span>;

    const valueForTrip = !isNewTrip ? dateParseZone(penaltyValue) : penaltyValue;
    const disabledAdditional = !isNewTrip && !penaltyCustom && !isItemCart;

    const deleteButton = i !== 0 ? (
      <div className={ styles['col-1-3'] }>
        <div className={ styles['add-conditions'] }>
          <FlatButton
            label={ BUTTONS.REMOVE }
            onClick={ () => onRemoveCancellation(i) }
            disabled={ disableFieldForEdit }
          />
        </div>
      </div>) : null;

    const labelClassName = penaltyAdditional ? styles.select_label : '';

    const disabledSumInRub = disableFieldForEdit || ProviderName === HOTEL_PROVIDER_VALUE.expedia;

    return (isValidValue || !isNewTrip) && (
      <div className={ styles['cancellation-penalties'] } key={ `cancellation-penalties_${i}` }>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-3'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              value={ valueForTrip }
              onChange={ value => onChangePenaltyInput(CANCELLATION_PENALTIES.FROM, value, i, FreeCancellation) }
              className={ `componentHook ${from && from[i] ? 'no-valid' : ''} ${disabledFieldStyles}` }
            />
            { validFromHtml }
            <label>{ TRIPSLABELS.PENALTY_FROM }</label>
          </div>
          <div className={ styles['col-attention'] }>
            <CheckboxUI
              value={ !!penaltyAdditional || false }
              onChange={ value => onChangePenaltyInput(FIELDS.ADDITIONAL, value, i) }
              disabled={ disabledAdditional || disableFieldForEdit }
            >
              <div className={ labelClassName }>{ TRIPSLABELS.ADDITIONAL }</div>
            </CheckboxUI>
          </div>
          { deleteButton }
        </div>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.TOTAL_PENALTY }
              value={ penaltyTotal }
              label={ TRIPSLABELS.SUM }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, i) }
              valid={ total && total.length ? total[i] : null }
              disabled={ disabledSumInRub }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.BASE_PENALTY }
              value={ penaltyBase }
              label={ TRIPSLABELS.SUM_BASE }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, i) }
              valid={ base && base.length ? base[i] : null }
              disabled={ disabledSumInRub }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
        </div>
        { this.renderCurrencyPrice(penaltyBaseCurrency, penaltyMFCurrency, penaltyMFRub, penaltyInclusiveCurrency, i) }
      </div>
    );
  };

  renderAddPenaltiesButton = () => {
    const {
      data: {
        Room: {
          FreeCancellation,
        },
      },
      funcProps: {
        onAddCancellation,
      },
    } = this.props;
    const { disableFieldForEdit } = this.state;

    const isAddDate = FreeCancellation !== null;

    return (isAddDate) && (
      <div className={ styles['add-conditions'] }>
        <FlatButton
          label={ BUTTONS.ADD }
          onClick={ onAddCancellation }
          disabled={ disableFieldForEdit }
        />
      </div>
    );
  };

  renderNightCount = () => {
    const {
      funcProps: {
        onChangeJsonInput,
      },
      data: {
        NightsCount,
        ProviderName,
      },
    } = this.props;
    const { disableFieldForEdit } = this.state;

    return ProviderName !== HOTEL_PROVIDER_VALUE.travelline && (
      <div className={ styles['col-1-3'] }>
        <Input
          field={ FIELDS.NIGHTS_COUNT }
          value={ NightsCount }
          label={ TRIPSLABELS.NIGHTS_COUNT }
          onChange={ onChangeJsonInput }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ disableFieldForEdit }
        />
      </div>
    );
  };

  renderNightsRow = () => {
    const {
      funcProps: {
        onChangeJsonInput,
      },
      data: {
        NightsPrice,
        NightsCount,
        ProviderName,
      },
    } = this.props;
    const { disableFieldForEdit } = this.state;

    return ProviderName !== HOTEL_PROVIDER_VALUE.travelline && ProviderName !== HOTEL_PROVIDER_VALUE.expedia && (
      <div className={ styles.row }>
        <div className={ styles['col-1-4'] }>
          <Input
            field={ FIELDS.NIGHTS_PRICE }
            value={ NightsPrice }
            label={ TRIPSLABELS.NIGHTS_PRICE }
            onChange={ onChangeJsonInput }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            disabled={ disableFieldForEdit }
            onKeyDown={ preventKeyNotNumber }
          />
        </div>
        <div className={ styles['col-1-4'] }>
          <Input
            field={ FIELDS.NIGHTS_COUNT }
            value={ NightsCount }
            label={ TRIPSLABELS.NIGHTS_COUNT }
            onChange={ onChangeJsonInput }
            disabled
          />
        </div>
      </div>
    );
  };

  renderRoomCategory = () => {
    const {
      data: {
        Room: {
          Category,
        },
        ProviderName,
      },
      funcProps: {
        onChangeJsonInput,
        onChangeRoomCategory,
      },
      rates,
      ratePlaneId,
    } = this.props;
    const { disableFieldForEdit } = this.state;

    if (ProviderName === HOTEL_PROVIDER_VALUE.travelline && rates) {
      return (
        <div className={ styles['col-attention'] }>
          <div className={ styles.select }>
            <div className={ styles.wrap }>
              <label>{ TRIPSLABELS.ROOMCATEGORY }</label>
              <select
                value={ ratePlaneId }
                onChange={ e => onChangeRoomCategory(e, FIELDS.ROOMCATEGORY, Number(e.target.value)) }
              >
                { rates.map(({ rate_name: name, id }) => (<option key={ id } value={ id }>{ name }</option>)) }
              </select>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.ROOMCATEGORY }
          value={ Category }
          label={ TRIPSLABELS.ROOMCATEGORY }
          onChange={ onChangeJsonInput }
          disabled={ disableFieldForEdit }
        />
      </div>
    );
  };

  renderBreakfastName = () => {
    const {
      data: {
        ProviderName,
        BreakfastName,
        Breakfast,
      },
      funcProps: {
        onChangeJsonInput,
        onValidateInput,
      },
      validation: {
        BreakfastName: breakfastName,
      },
      country,
    } = this.props;

    const foreignHotelMeal = ProviderName === HOTEL_PROVIDER_VALUE.ostrovok
      && country.length > 0
      && country !== LOCALE.RU.toUpperCase()
      && Breakfast;

    if (!foreignHotelMeal) return null;

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.BREAKFASTNAME }
          value={ BreakfastName }
          label={ TRIPSLABELS.BREAKFASTNAME }
          onChange={ onChangeJsonInput }
          onBlur={ onValidateInput }
          valid={ breakfastName }
        />
      </div>
    );
  }

  handleConfirmationChangeHotel = () => {
    const { funcProps: {
      onResetMainHotelFields,
    } } = this.props;

    onResetMainHotelFields();

    this.setState({
      showModal: false,
      showModalEditMainHotelFields: true,
      disableHotelAutocomplete: true,
    });
  }

  handleSave = (data) => {
    this.props.updateMainHotelFields(data);

    this.setState({
      showModalEditMainHotelFields: false,
    });
  }

  handleCancel = () => this.setState({ showModalEditMainHotelFields: false });

  renderProviders = () => {
    const { hotelProps: { providerNameOptions }, isItemCart } = this.props;

    return providerNameOptions.map(({ value, label }, key) => {
      const hidden = value === HOTEL_PROVIDER_VALUE.booking ||
        (isItemCart && value === HOTEL_PROVIDER_VALUE.expedia);

      return <option key={ key } value={ value } hidden={ hidden }>{ label }</option>;
    });
  }

  renderEditMainHotelFields = () => {
    const {
      hotelProps,
      data,
      tripService,
    } = this.props;

    return (
      <EditMainHotelFields
        rates={ this.state.rates }
        hotelProps={ hotelProps }
        data={ data }
        onShowModal={ this.handleCancel }
        onCancel={ this.handleCancel }
        onConfirm={ this.handleSave }
        tripService={ tripService }
      />
    );
  }

  renderConfirmAddHotel = () => (
    <Dialog
      onClick={ () => { this.setState({ showModal: false }); } }
      width={ COMPONENTS.DIALOG.WIDTH.MIDDLE }
    >
      <div className={ styles.dialog }>
        <div>{LABELS.TEXT_ADD_HOTEL}</div>
        <div className={ styles.dialog_buttons }>
          <Button
            label={ LABELS.YES }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            onClick={ this.handleConfirmationChangeHotel }
          />
          <Button
            label={ LABELS.NO }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ () => { this.setState({ showModal: false }); } }
          />
        </div>
      </div>
    </Dialog>
  );

  renderBookingNumberAndDate = () => {
    const {
      isItemCart,
      data: { ReservationNumber },
      funcProps: { onChangeJsonInput, onValidateInput },
      validation: { ReservationNumber: validationReservationNumber },
    } = this.props;
    const { disableFieldForEdit } = this.state;

    if (isItemCart) return null;

    return (
      <div className={ styles['col-2-3'] }>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.RESERVATIONNUMBER }
              value={ ReservationNumber }
              label={ TRIPSLABELS.RESERVATIONNUMBER }
              onChange={ onChangeJsonInput }
              onBlur={ onValidateInput }
              valid={ validationReservationNumber }
              disabled={ disableFieldForEdit }
            />
          </div>
          <div className={ styles['col-1-2'] }>
            { this.renderReservationDate() }
          </div>
        </div>
      </div>
    );
  }

  renderHotelOrderId = () => {
    const {
      isItemCart,
      data: { HotelOrderId },
      funcProps: { onChangeJsonInput },
    } = this.props;
    const { disableFieldForEdit } = this.state;

    if (isItemCart) return null;

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <Input
            field={ FIELDS.HOTEL_ORDER_ID }
            value={ HotelOrderId }
            label={ TRIPSLABELS.HOTEL_ORDER_ID }
            onChange={ onChangeJsonInput }
            disabled={ disableFieldForEdit }
          />
        </div>
      </div>
    );
  }

  renderAdditionalInfo = () => {
    const {
      isItemCart,
      data: { AdditionalInfo },
      funcProps: { onChangeJsonInput },
    } = this.props;

    if (isItemCart) return null;

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1'] }>
          <Input
            field={ FIELDS.ADDITIONAL_INFO }
            value={ AdditionalInfo }
            label={ TRIPSLABELS.ADDITIONAL_INFO }
            onChange={ onChangeJsonInput }
          />
        </div>
      </div>
    );
  }

  renderINN = () => {
    const {
      data: { ProviderName },
      hotelProps: { ProviderInn },
      validation: { ProviderInn: validationInn },
      funcProps: { onChangeNumberInput, onValidateInn },
    } = this.props;

    if (!PROVIDERS_AVAILABLE_INN.includes(ProviderName)) return null;

    return (
      <div className={ styles['col-1-6'] }>
        <Input
          field={ FIELDS.PROVIDER_INN }
          value={ ProviderInn }
          label={ TRIPSLABELS.INN }
          onChange={ onChangeNumberInput }
          onBlur={ onValidateInn }
          valid={ validationInn }
        />
      </div>
    );
  }

  renderReportAmount = () => {
    const {
      isNewTrip,
      data: { ProviderName },
      hotelProps: { ReportAmount },
      funcProps: { onChangeReportAmount },
      isFromOrderByNumber,
    } = this.props;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.aanda) return null;

    const desabledSum = !isNewTrip || isFromOrderByNumber;

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.REPORT_AMOUNT }
          value={ ReportAmount }
          label={ TRIPSLABELS.REPORT_AMOUNT }
          onChange={ onChangeReportAmount }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ desabledSum }
        />
      </div>
    );
  }

  render() {
    const {
      hotelProps: {
        companyId,
        employeesList,
        departments,
        projects,
        departmentId,
        employeeId,
        projectId,
        contact,
      },
      isCreateOrder,
      data: {
        GuestCount,
        CheckinDate,
        CheckoutDate,
        Hotel: {
          Stars,
          Address,
          City,
          Phone,
          CheckinTime,
          CheckoutTime,
        },
        ProviderName,
        CustomCheckInDate,
        CustomCheckOutDate,
        Room: {
          FreeCancellation,
        },
      },
      priceDetails: {
        EarlyCheckIn,
        LateCheckOut,
      },
      cancellationPenalties,
      validation: {
        DepartmentId,
        ProjectId,
        GuestCount: validationGuestCount,
        CheckinDate: validationCheckinDate,
        CheckoutDate: validationCheckoutDate,
        Hotel: {
          Address: validationAddress,
          City: validationCity,
        },
        EarlyCheckIn: validationEarlyCheckIn,
        LateCheckOut: validationLateCheckOut,
      },
      funcProps: {
        onAddEmployee,
        onRemoveEmployee,
        onSelectDepartment,
        onSelectProject,
        onChangeJsonInput,
        onChangeInput,
        onValidateInput,
        onChangeDate,
        onValidateDate,
        onChangeECLCCheckbox,
        onChangePriceDetailsInput,
        onFocusCheckoutDate,
        onChangeTimeInput,
        onBlurTimeInput,
        onChangeProviderName,
        onAddCancellation,
        onChangeStateInput,
      },
      isNewTrip,
      errors,
      mandatoryProject,
      meal,
    } = this.props;
    const {
      disableMainHotelFields,
      showModal,
      showModalEditMainHotelFields,
      disableFieldForEdit,
      disabledFieldStyles,
    } = this.state;

    const selectCompanyHtml = isCreateOrder && this.renderCompany();
    const disableSelectProject = isCreateOrder && !companyId;
    const validCheckinHtml = validationCheckinDate && <span className='error-msg'>{ validationCheckinDate }</span>;
    const validCheckoutHtml = validationCheckoutDate && <span className='error-msg'>{ validationCheckoutDate }</span>;
    const cancellationPenaltiesList = cancellationPenalties
      ? cancellationPenalties.map((penalty, i) => (
        this.renderCancellationPenalties(
          penalty.From,
          penalty.Total,
          penalty.Base,
          penalty.BaseCurrency,
          penalty.MFInCurrency,
          penalty.MFRubPenalties,
          penalty.ProviderPrice,
          penalty.Additional,
          penalty.IsCustomCancellation,
          i)
      ))
      : onAddCancellation();

    return (
      <div>
        <h4>Данные ваучера</h4>
        <div className={ styles['employee-panel'] }>
          <div className={ styles['employee-panel-title'] }>
            Число сотрудников : { employeesList.length }
          </div>
          <FlatButton
            label={ TRIPSLABELS.INCREASE }
            disabled={ employeesList.length > 5 }
            onClick={ onAddEmployee }
          />
          <FlatButton
            label={ TRIPSLABELS.DECREASE }
            disabled={ employeesList.length < 2 }
            onClick={ onRemoveEmployee }
          />
        </div>
        { selectCompanyHtml }
        <div className={ styles.row }>
          <div className={ styles['employees-list'] }>
            { employeesList.map(this.renderEmployeeSuggest) }
          </div>
        </div>
        <div className={ styles.row }>
          <SelectDepartment
            isHotel
            departments={ departments }
            employeesList={ employeesList }
            validationDepartment={ DepartmentId }
            currentDepartmentId={ departmentId }
            currentEmployeeId={ employeeId }
            onSelect={ onSelectDepartment }
          />
          <SelectProject
            disabled={ disableSelectProject }
            currentProjectId={ projectId }
            validationProject={ ProjectId }
            mandatoryProject={ mandatoryProject }
            projects={ projects }
            onSelect={ onSelectProject }
          />
        </div>
        { this.renderAnalytics() }
        <div className={ styles.row }>
          { this.renderBookingNumberAndDate() }
          <div className={ styles['col-attention'] }>
            <Input
              field={ FIELDS.GUESTCOUNT }
              value={ GuestCount }
              label={ TRIPSLABELS.GUESTCOUNT }
              onChange={ onChangeJsonInput }
              onBlur={ onValidateInput }
              valid={ validationGuestCount }
            />
          </div>
        </div>
        { this.renderHotelOrderId() }
        <div className={ styles.row }>
          <div className={ styles['col-attention'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat=''
              locale={ LOCALERU }
              value={ CheckinDate }
              renderDay={ this.renderDay }
              onChange={ value => onChangeDate(FIELDS.CHECKIN_DATE, value) }
              onBlur={ () => onValidateDate(FIELDS.CHECKIN_DATE) }
              className={ `componentHook ${validationCheckinDate ? 'no-valid' : ''} ${disabledFieldStyles}` }
            />
            <label>Дата заезда</label>
            { validCheckinHtml }
          </div>
          <div className={ styles['col-attention'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat=''
              locale={ LOCALERU }
              value={ CheckoutDate }
              renderDay={ this.renderDay }
              onChange={ value => onChangeDate(FIELDS.CHECKOUT_DATE, value) }
              onBlur={ () => onValidateDate(FIELDS.CHECKOUT_DATE) }
              onFocus={ onFocusCheckoutDate }
              className={ `componentHook ${validationCheckoutDate ? 'no-valid' : ''} ${disabledFieldStyles}` }
            />
            <label>Дата выезда</label>
            { validCheckoutHtml }
          </div>
          { this.renderNightCount() }
        </div>
        {this.renderHotelNameAutocomplete()}
        <div className={ styles.row }>
          { this.renderHotelName() }
          <div className={ styles['col-1-6'] }>
            <div className={ styles.select }>
              <div className={ styles.wrap }>
                <label>{TRIPSLABELS.HOTEL_STARS}</label>
                <select
                  value={ Stars }
                  onChange={ e => onChangeJsonInput(e, FIELDS.HOTELSTARS, e.target.value) }
                  disabled={ disableMainHotelFields }
                >
                  <option value={ 0 }>Без звезд</option>
                  <option value={ 1 }>1</option>
                  <option value={ 2 }>2</option>
                  <option value={ 3 }>3</option>
                  <option value={ 4 }>4</option>
                  <option value={ 5 }>5</option>
                </select>
              </div>
            </div>
          </div>
          <div className={ styles['col-1-6'] }>
            <div className={ styles.select }>
              <div className={ styles.wrap }>
                <label>{TRIPSLABELS.PROVIDER_NAME}</label>
                <select
                  value={ ProviderName }
                  onChange={ e => onChangeProviderName(e, FIELDS.PROVIDERNAME, e.target.value) }
                  disabled={ disableFieldForEdit }
                >
                  { this.renderProviders() }
                </select>
              </div>
            </div>
          </div>
          { this.renderINN() }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1'] }>
            <Input
              field={ FIELDS.HOTELADDRESS }
              value={ Address }
              label={ TRIPSLABELS.ADDRESS }
              onChange={ onChangeJsonInput }
              onBlur={ onValidateInput }
              valid={ validationAddress }
              disabled={ disableMainHotelFields }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.HOTELCITY }
              value={ City }
              label={ TRIPSLABELS.CITY }
              onChange={ onChangeJsonInput }
              onBlur={ onValidateInput }
              valid={ validationCity }
              disabled={ disableMainHotelFields }
            />
          </div>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.HOTELPHONE }
              value={ Phone }
              label={ TRIPSLABELS.PHONE }
              onChange={ onChangeJsonInput }
              disabled={ disableMainHotelFields }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <Input
              field={ FIELDS.CONTACT }
              value={ contact }
              label={ TRIPSLABELS.CONTACT }
              onChange={ onChangeInput }
              disabled={ disableMainHotelFields }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-attention'] }>
            <Input
              field={ FIELDS.HOTELCHECKINTIME }
              value={ CheckinTime }
              label={ TRIPSLABELS.HOTELCHECKINTIME }
              onChange={ onChangeTimeInput }
              onBlur={ onBlurTimeInput }
              valid={ errors[FIELDS.HOTELCHECKINTIME] }
            />
          </div>
          <div className={ styles['col-attention'] }>
            <Input
              field={ FIELDS.HOTELCHECKOUTTIME }
              value={ CheckoutTime }
              label={ TRIPSLABELS.HOTELCHECKOUTTIME }
              onChange={ onChangeTimeInput }
              onBlur={ onBlurTimeInput }
              valid={ errors[FIELDS.HOTELCHECKOUTTIME] }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-attention'] }>
            <Checkbox
              field={ FIELDS.CUSTOMCHECKINDATE }
              value={ CustomCheckInDate || false }
              label={ TRIPSLABELS.CUSTOMCHECKINDATE }
              onChange={ onChangeECLCCheckbox }
              disabled={ disableFieldForEdit }
            />
          </div>
          <div className={ styles['col-attention'] }>
            <Checkbox
              field={ FIELDS.CUSTOMCHECKOUTDATE }
              value={ CustomCheckOutDate || false }
              label={ TRIPSLABELS.CUSTOMCHECKOUTDATE }
              onChange={ onChangeECLCCheckbox }
              disabled={ disableFieldForEdit }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.EARLYCHECKIN }
              value={ EarlyCheckIn }
              label={ TRIPSLABELS.EARLYCHECKIN }
              onChange={ onChangePriceDetailsInput }
              valid={ validationEarlyCheckIn }
              disabled={ disableFieldForEdit }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.LATECHECKOUT }
              value={ LateCheckOut }
              label={ TRIPSLABELS.LATECHECKOUT }
              onChange={ onChangePriceDetailsInput }
              valid={ validationLateCheckOut }
              disabled={ disableFieldForEdit }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
        </div>
        <div className={ `${styles.row} ${styles.panel}` }>
          { this.renderRoomCategory() }
          { this.renderBreakfastName() }
          <MealFields
            meal={ meal }
            onChangeStateInput={ onChangeStateInput }
          />
        </div>
        { this.renderAdditionalInfo() }
        <div className={ styles.row }>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <h4>{LABELS.CANCELLATION_CONDITION}</h4>
              { this.renderCustomRate() }
            </div>
            <div className={ styles['col-1-3'] }>
              <h4>{ LABELS.PERIOD }</h4>
            </div>
          </div>
          <div className={ `${styles.row} ${styles.panel}` }>
            <div className={ styles['col-attention'] }>
              <div className={ styles.select }>
                <label>Дата снятия брони без потерь</label>
                <DatePicker
                  dateFormat={ DEFAULTDATE }
                  timeFormat={ DEFAULTTIME }
                  value={ !isNewTrip && FreeCancellation ? dateParseZone(FreeCancellation) : FreeCancellation }
                  onChange={ value => onChangeDate(FIELDS.ROOMFREECANCELLATION, value) }
                  className={ `${disabledFieldStyles} componentHook` }
                />
              </div>
            </div>
            <div className={ styles['col-1-2'] }>{ cancellationPenaltiesList }</div>
            { this.renderAddPenaltiesButton() }
          </div>
          { this.renderNightsRow() }
          <div className={ styles.row }>
            { this.renderReportAmount() }
          </div>
        </div>
        {showModal && this.renderConfirmAddHotel()}
        {showModalEditMainHotelFields && this.renderEditMainHotelFields()}
      </div>
    );
  }
}

export default CommonFields;
