import React from 'react';
import PropTypes from 'prop-types';

import { FIELDS, TRIPSLABELS } from '../../../../../../bi/constants/trips';
import { HOTEL_PROVIDER_VALUE } from '../../../../../../bi/constants/hotel';

import styles from '../../../../styles/form.module.scss';

const Providers = ({ onChangeProviderName, providerName, tripService }) => {
  const renderProviders = () => {
    const providerNameOptions = tripService.getHotelProviderName();

    return providerNameOptions.map(({ value, label }, key) => {
      const hidden =
        value === HOTEL_PROVIDER_VALUE.booking
        || value === HOTEL_PROVIDER_VALUE.expedia
        || value === HOTEL_PROVIDER_VALUE.tripcom;

      return <option key={ key } value={ value } hidden={ hidden }>{ label }</option>;
    });
  };

  return (
    <div className={ styles['col-1-6'] }>
      <div className={ styles.select }>
        <div className={ styles.wrap }>
          <label>{ TRIPSLABELS.PROVIDER_NAME }</label>
          <select
            value={ providerName }
            onChange={ ({ target: { value } }) => onChangeProviderName(FIELDS.PROVIDERNAME, value) }
          >
            { renderProviders() }
          </select>
        </div>
      </div>
    </div>
  );
};

Providers.propTypes = {
  onChangeProviderName: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { Providers };

