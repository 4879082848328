import PropTypes from 'prop-types';
import React from 'react';

import { HOTEL_PROVIDER_VALUE } from '../../../../bi/constants/hotel';

import styles from '../../styles/form.module.scss';

const LABELS = {
  VARIANT_1: 'Внимание! Поставщик работает со ставкой НДС 20%, занести поездку надо с НДС.',
  VARIANT_2: 'Внимание! Поставщик работает без НДС, занести поездку надо без НДС.',
  VARIANT_3: 'Внимание! Поставщик Вердехо3д работает без НДС, а это значит, что в БО поездка должна быть без НДС',
};

const LABELS_BY_PROVIDER_NAME = {
  [HOTEL_PROVIDER_VALUE.direct]: LABELS.VARIANT_1,
  [HOTEL_PROVIDER_VALUE.sawady3d]: LABELS.VARIANT_1,
  [HOTEL_PROVIDER_VALUE.alpaka3d]: LABELS.VARIANT_2,
  [HOTEL_PROVIDER_VALUE.alpaka]: LABELS.VARIANT_2,
  [HOTEL_PROVIDER_VALUE.verdeho3d]: LABELS.VARIANT_3,
};

const AttentionVat = ({ providerName }) => {
  if (!providerName || (
    providerName !== HOTEL_PROVIDER_VALUE.verdeho3d
    && providerName !== HOTEL_PROVIDER_VALUE.direct
    && providerName !== HOTEL_PROVIDER_VALUE.sawady3d
    && providerName !== HOTEL_PROVIDER_VALUE.alpaka
    && providerName !== HOTEL_PROVIDER_VALUE.alpaka3d
  )) {
    return null;
  }

  return (
    <span className={ `error-msg ${styles['error-msg-vat']}` }>
      { LABELS_BY_PROVIDER_NAME[providerName] }
    </span>
  );
};

AttentionVat.propTypes = {
  providerName: PropTypes.string.isRequired,
};


export { AttentionVat };
